import React, { Fragment } from "react";
import ArticleBlock from "cloud-elephants-core/src/components/ArticleBlock";

const ColorsPage = () => {
  return (
    <>
      <div class="border-solid border mt-6 mb-10 border-gray-50 shadow-xl border p-6 px-10 rounded-lg">
        <h5 class="text-blue-500 uppercase w-full border-b border-blue-500 border-solid pb-2 font-bold mb-10">
          Colors
        </h5>
        <h6 class="mb-2">White</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-white border border-solid border-gray-100"></div>
        </section>
        <h6 class="mb-2">Contrast</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-white border border-solid border-contrast"></div>
        </section>
        <h6 class="mb-2">Form Background</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-form-bg border border-solid border-gray-100"></div>
        </section>
        <h6 class="mb-2">Black</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-black"></div>
        </section>
        <h6 class="mb-2">Light Background</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-lightmode-50"></div>
          <div class="w-16 h-16 bg-lightmode-100"></div>
          <div class="w-16 h-16 bg-lightmode-200"></div>
          <div class="w-16 h-16 bg-lightmode-300"></div>
          <div class="w-16 h-16 bg-lightmode-400"></div>
          <div class="w-16 h-16 bg-lightmode-500"></div>
          <div class="w-16 h-16 bg-lightmode-600"></div>
          <div class="w-16 h-16 bg-lightmode-700"></div>
          <div class="w-16 h-16 bg-lightmode-800"></div>
          <div class="w-16 h-16 bg-lightmode-900"></div>
        </section>
        <h6 class="mb-2">Dark Background</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-darkmode-50"></div>
          <div class="w-16 h-16 bg-darkmode-100"></div>
          <div class="w-16 h-16 bg-darkmode-200"></div>
          <div class="w-16 h-16 bg-darkmode-300"></div>
          <div class="w-16 h-16 bg-darkmode-400"></div>
          <div class="w-16 h-16 bg-darkmode-500"></div>
          <div class="w-16 h-16 bg-darkmode-600"></div>
          <div class="w-16 h-16 bg-darkmode-700"></div>
          <div class="w-16 h-16 bg-darkmode-800"></div>
          <div class="w-16 h-16 bg-darkmode-900"></div>
        </section>
        <h6 class="mb-2">Primary</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-primary-50"></div>
          <div class="w-16 h-16 bg-primary-100"></div>
          <div class="w-16 h-16 bg-primary-200"></div>
          <div class="w-16 h-16 bg-primary-300"></div>
          <div class="w-16 h-16 bg-primary-400"></div>
          <div class="w-16 h-16 bg-primary-500"></div>
          <div class="w-16 h-16 bg-primary-600"></div>
          <div class="w-16 h-16 bg-primary-700"></div>
          <div class="w-16 h-16 bg-primary-800"></div>
          <div class="w-16 h-16 bg-primary-900"></div>
        </section>
        <h6 class="mb-2">Secondary</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-secondary-50"></div>
          <div class="w-16 h-16 bg-secondary-100"></div>
          <div class="w-16 h-16 bg-secondary-200"></div>
          <div class="w-16 h-16 bg-secondary-300"></div>
          <div class="w-16 h-16 bg-secondary-400"></div>
          <div class="w-16 h-16 bg-secondary-500"></div>
          <div class="w-16 h-16 bg-secondary-600"></div>
          <div class="w-16 h-16 bg-secondary-700"></div>
          <div class="w-16 h-16 bg-secondary-800"></div>
          <div class="w-16 h-16 bg-secondary-900"></div>
        </section>
        <h6 class="mb-2">Success</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-success-50"></div>
          <div class="w-16 h-16 bg-success-100"></div>
          <div class="w-16 h-16 bg-success-200"></div>
          <div class="w-16 h-16 bg-success-300"></div>
          <div class="w-16 h-16 bg-success-400"></div>
          <div class="w-16 h-16 bg-success-500"></div>
          <div class="w-16 h-16 bg-success-600"></div>
          <div class="w-16 h-16 bg-success-700"></div>
          <div class="w-16 h-16 bg-success-800"></div>
          <div class="w-16 h-16 bg-success-900"></div>
        </section>
        <h6 class="mb-2">Info</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-info-50"></div>
          <div class="w-16 h-16 bg-info-100"></div>
          <div class="w-16 h-16 bg-info-200"></div>
          <div class="w-16 h-16 bg-info-300"></div>
          <div class="w-16 h-16 bg-info-400"></div>
          <div class="w-16 h-16 bg-info-500"></div>
          <div class="w-16 h-16 bg-info-600"></div>
          <div class="w-16 h-16 bg-info-700"></div>
          <div class="w-16 h-16 bg-info-800"></div>
          <div class="w-16 h-16 bg-info-900"></div>
        </section>
        <h6 class="mb-2">Warning</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-warning-50"></div>
          <div class="w-16 h-16 bg-warning-100"></div>
          <div class="w-16 h-16 bg-warning-200"></div>
          <div class="w-16 h-16 bg-warning-300"></div>
          <div class="w-16 h-16 bg-warning-400"></div>
          <div class="w-16 h-16 bg-warning-500"></div>
          <div class="w-16 h-16 bg-warning-600"></div>
          <div class="w-16 h-16 bg-warning-700"></div>
          <div class="w-16 h-16 bg-warning-800"></div>
          <div class="w-16 h-16 bg-warning-900"></div>
        </section>
        <h6 class="mb-2">Danger</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-danger-50"></div>
          <div class="w-16 h-16 bg-danger-100"></div>
          <div class="w-16 h-16 bg-danger-200"></div>
          <div class="w-16 h-16 bg-danger-300"></div>
          <div class="w-16 h-16 bg-danger-400"></div>
          <div class="w-16 h-16 bg-danger-500"></div>
          <div class="w-16 h-16 bg-danger-600"></div>
          <div class="w-16 h-16 bg-danger-700"></div>
          <div class="w-16 h-16 bg-danger-800"></div>
          <div class="w-16 h-16 bg-danger-900"></div>
        </section>
        <h6 class="mb-2">Gray</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-gray-50"></div>
          <div class="w-16 h-16 bg-gray-100"></div>
          <div class="w-16 h-16 bg-gray-200"></div>
          <div class="w-16 h-16 bg-gray-300"></div>
          <div class="w-16 h-16 bg-gray-400"></div>
          <div class="w-16 h-16 bg-gray-500"></div>
          <div class="w-16 h-16 bg-gray-600"></div>
          <div class="w-16 h-16 bg-gray-700"></div>
          <div class="w-16 h-16 bg-gray-800"></div>
          <div class="w-16 h-16 bg-gray-900"></div>
        </section>
        <h6 class="mb-2">Red</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-red-50"></div>
          <div class="w-16 h-16 bg-red-100"></div>
          <div class="w-16 h-16 bg-red-200"></div>
          <div class="w-16 h-16 bg-red-300"></div>
          <div class="w-16 h-16 bg-red-400"></div>
          <div class="w-16 h-16 bg-red-500"></div>
          <div class="w-16 h-16 bg-red-600"></div>
          <div class="w-16 h-16 bg-red-700"></div>
          <div class="w-16 h-16 bg-red-800"></div>
          <div class="w-16 h-16 bg-red-900"></div>
        </section>
        <h6 class="mb-2">Orange</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-orange-50"></div>
          <div class="w-16 h-16 bg-orange-100"></div>
          <div class="w-16 h-16 bg-orange-200"></div>
          <div class="w-16 h-16 bg-orange-300"></div>
          <div class="w-16 h-16 bg-orange-400"></div>
          <div class="w-16 h-16 bg-orange-500"></div>
          <div class="w-16 h-16 bg-orange-600"></div>
          <div class="w-16 h-16 bg-orange-700"></div>
          <div class="w-16 h-16 bg-orange-800"></div>
          <div class="w-16 h-16 bg-orange-900"></div>
        </section>
        <h6 class="mb-2">Yellow</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-yellow-50"></div>
          <div class="w-16 h-16 bg-yellow-100"></div>
          <div class="w-16 h-16 bg-yellow-200"></div>
          <div class="w-16 h-16 bg-yellow-300"></div>
          <div class="w-16 h-16 bg-yellow-400"></div>
          <div class="w-16 h-16 bg-yellow-500"></div>
          <div class="w-16 h-16 bg-yellow-600"></div>
          <div class="w-16 h-16 bg-yellow-700"></div>
          <div class="w-16 h-16 bg-yellow-800"></div>
          <div class="w-16 h-16 bg-yellow-900"></div>
        </section>
        <h6 class="mb-2">Green</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-green-50"></div>
          <div class="w-16 h-16 bg-green-100"></div>
          <div class="w-16 h-16 bg-green-200"></div>
          <div class="w-16 h-16 bg-green-300"></div>
          <div class="w-16 h-16 bg-green-400"></div>
          <div class="w-16 h-16 bg-green-500"></div>
          <div class="w-16 h-16 bg-green-600"></div>
          <div class="w-16 h-16 bg-green-700"></div>
          <div class="w-16 h-16 bg-green-800"></div>
          <div class="w-16 h-16 bg-green-900"></div>
        </section>
        <h6 class="mb-2">Blue</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-blue-50"></div>
          <div class="w-16 h-16 bg-blue-100"></div>
          <div class="w-16 h-16 bg-blue-200"></div>
          <div class="w-16 h-16 bg-blue-300"></div>
          <div class="w-16 h-16 bg-blue-400"></div>
          <div class="w-16 h-16 bg-blue-500"></div>
          <div class="w-16 h-16 bg-blue-600"></div>
          <div class="w-16 h-16 bg-blue-700"></div>
          <div class="w-16 h-16 bg-blue-800"></div>
          <div class="w-16 h-16 bg-blue-900"></div>
        </section>
        <h6 class="mb-2">Purple</h6>
        <section class="w-full my-4 flex flex-wrap">
          <div class="w-16 h-16 bg-purple-50"></div>
          <div class="w-16 h-16 bg-purple-100"></div>
          <div class="w-16 h-16 bg-purple-200"></div>
          <div class="w-16 h-16 bg-purple-300"></div>
          <div class="w-16 h-16 bg-purple-400"></div>
          <div class="w-16 h-16 bg-purple-500"></div>
          <div class="w-16 h-16 bg-purple-600"></div>
          <div class="w-16 h-16 bg-purple-700"></div>
          <div class="w-16 h-16 bg-purple-800"></div>
          <div class="w-16 h-16 bg-purple-900"></div>
        </section>
      </div>
    </>
  );
};

export default ColorsPage;
